/* @component id="cancel-button" version="1.0.0" */
"use client";
import React from "react";
import { Button } from "./ui/button";

interface CancelBtnProps {
  variant?:
    | "link"
    | "default"
    | "destructive"
    | "outline"
    | "secondary"
    | "ghost";
  children?: any;
}

export default function CancelButton({ variant, children }: CancelBtnProps) {
  const goHome = () => {
    window.location.href = "/";
  };
  return (
    <Button type="button" onClick={goHome} variant={variant || "outline"}>
      {children || "Cancel"}
    </Button>
  );
}
