/*   This is the main landing page
- Users will be greated with a welcome message
- Asked to login if not already
- Guided through selecting a form and completing it
*/
"use client";
import AuthButton from "@/components/Auth/AuthButton";
import Link from "next/link";
import useAuthorization from "@/components/Auth/isAuthorized";
import SpinnerCheck from "@/components/spinner-check";
import { useState } from "react";
import {
  ChevronLeft,
  CircleX,
  Headset,
  UserPen,
  UserPlus,
  Users,
  UserX,
} from "lucide-react";
import CancelButton from "@/components/cancel-button";

export default function Home() {
  const { isAuthorized, session, isLoaded, location } = useAuthorization();
  // Menu State
  const [showStaffRequests, setShowStaffRequests] = useState(false);
  // const [showClientRequests, setShowClientRequests] = useState(false);

  // Not loaded, No Session
  if (!isLoaded && !session)
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <SpinnerCheck size={"125px"} />
        <h2 className="my-4 text-2xl font-semibold">Logging in...</h2>
      </div>
    );

  if (showStaffRequests) return <StaffRequestsMenu />;
  // if (showClientRequests) return <ClientRequestsMenu />;

  // Loaded, No Session
  if (isLoaded && !session)
    return (
      <div className="flex flex-col justify-center h-full px-6 mb-0 pb-20">
        <div className="h-100 w-100">
          <h1 className="text-4xl font-semibold text-center">Welcome! </h1>
          <h4 className="text-lg font-semibold text-center">
            Please sign in to continue
          </h4>
        </div>
        <div className="w-full flex flex-col items-center">
          <div className="w-1/2">
            <AuthButton />
          </div>
        </div>
      </div>
    );

  // Loaded and session
  if (isLoaded && session)
    return (
      <div className="flex flex-col justify-center h-full px-6 mb-2 pb-0">
        <div className="h-100 w-100">
          <h1 className="text-4xl font-semibold text-center">
            Welcome {session?.user.givenName}!
          </h1>
          <h4 className="text-lg font-semibold text-center">
            What would you like to get started on?
          </h4>
        </div>
        {/* Support Requests Card*/}
        <div className={`flex flex-col gap-4 p-8 mx-auto`}>
          <Link href="/support">
            <div className="flex items-center w-[450px] border rounded-xl p-4 hover:cursor-pointer hover:bg-gray-100 transform transition duration-200 hover:scale-105">
              <Headset className="w-10 h-12 ml-4 mr-6 text-primary" />
              <div className="flex-1">
                <p className="text-lg font-semibold">Support Request</p>
                <p className="text-sm text-muted-foreground">
                  Request technical assistance from the Gazelle Support team
                </p>
              </div>
            </div>
          </Link>

          {/* User Requests Card */}
          {isAuthorized && location && (
            <div
              onClick={() => setShowStaffRequests(true)}
              className="flex items-center w-[450px] border rounded-xl p-4 hover:cursor-pointer hover:bg-gray-100 transform transition duration-200 hover:scale-105"
            >
              <Users className="w-10 h-12 ml-4 mr-6 text-primary" />
              <div className="flex-1">
                <p className="text-lg font-semibold">Staff Requests</p>
                <p className="text-sm text-muted-foreground">
                  Submit requests to Create, Update or Disable IO Staff and
                  volunteer accounts.
                </p>
              </div>
            </div>
          )}

          {/* Deactivation Requests for CAD only */}
          {/* {isCanadian && location && (
            <Link href="/deactivate-service">
              <div className="flex items-center w-[450px] border rounded-xl p-4 hover:cursor-pointer hover:bg-gray-100 transform transition duration-200 hover:scale-105">
                <CircleX className="w-10 h-12 ml-4 mr-4 text-primary" />
                <div className="flex-1">
                  <p className="text-lg font-semibold">
                    Service Deactivation Request
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Request the cancellation of a subscription, service or other
                    product fulfilled by Gazelle.
                  </p>
                </div>
              </div>
            </Link>
          )} */}

          {/* <div
            onClick={() => setShowClientRequests(true)}
            className="flex items-center w-[450px] border rounded-xl p-4 hover:cursor-pointer hover:bg-gray-100 transform transition duration-200 hover:scale-105"
          >
            <CircleUser className="w-10 h-12 ml-4 mr-6 text-primary" />
            <div className="flex-1">
              <p className="text-lg font-semibold">Client Requests</p>
              <p className="text-sm text-muted-foreground">
                Submit requests for client related items like order, service
                modification and more!
              </p>
            </div>
          </div> */}
        </div>
      </div>
    );
}

function StaffRequestsMenu() {
  return (
    <div className="flex flex-col justify-center h-full px-6 mb-0 pb-0">
      <div className="h-100 w-full mb-4">
        <div className="col-span-8">
          <h1 className="text-4xl font-semibold text-center">
            Staff User Requests
          </h1>
          <h4 className="text-lg font-semibold text-center">
            What would you like to get started on?
          </h4>
        </div>
      </div>

      <div className="flex pb-4 mt-4 justify-center">
        {/* ALWAYS VISIBLE */}
        <div className="flex-1 flex flex-col space-y-4 pr-4 max-w-[450px] mx-auto">
          <Link href="/create">
            <div className="mx-auto flex items-center max-w-[350px] border rounded-xl px-2 py-4 hover:cursor-pointer hover:bg-gray-100 transform transition duration-200 hover:scale-105">
              <UserPlus className="w-10 h-12 ml-4 mr-4 text-primary" />
              <div className="flex-1">
                <p className="text-md font-semibold">Create User(s)</p>
                <p className="text-xs text-muted-foreground">
                  Request to create of one or more Intelligent Office staff
                  users
                </p>
              </div>
            </div>
          </Link>

          <Link href="/update">
            <div className="mx-auto flex items-center max-w-[350px] border rounded-xl px-2 py-4 hover:cursor-pointer hover:bg-gray-100 transform transition duration-200 hover:scale-105">
              <UserPen className="w-10 h-12 ml-4 mr-4 text-primary" />
              <div className="flex-1">
                <p className="text-md font-semibold">Update User(s)</p>
                <p className="text-xs text-muted-foreground">
                  Request changes to one or more Intelligent Office staff users
                </p>
              </div>
            </div>
          </Link>

          <Link href="/disable">
            <div className="mx-auto flex items-center max-w-[350px] border rounded-xl px-2 py-4 hover:cursor-pointer hover:bg-gray-100 transform transition duration-200 hover:scale-105">
              <UserX className="w-10 h-12 ml-4 mr-4 text-primary" />
              <div className="flex-1">
                <p className="text-md font-semibold">Disable User(s)</p>
                <p className="text-xs text-muted-foreground">
                  Request to disable one or more Intelligent Office staff users
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div className="mt-2 mx-auto mb-4">
        <CancelButton variant="outline">
          <ChevronLeft /> Go Back
        </CancelButton>
      </div>
    </div>
  );
}

function ClientRequestsMenu() {
  return (
    <div className="flex flex-col justify-center h-full px-6 mb-0 pb-0">
      <div className="h-100 w-full mb-4">
        <div className="col-span-8">
          <h1 className="text-4xl font-semibold text-center">
            Client Service Requests
          </h1>
          <h4 className="text-lg font-semibold text-center">
            What would you like to get started on?
          </h4>
        </div>
      </div>
      {/* 
      <div className="flex pb-4 mt-4 justify-center">

        <div className="flex-1 flex flex-col space-y-4 pr-4 max-w-[450px] mx-auto">
          <Link href="/deactivate-service">
            <div className="mx-auto flex items-center max-w-[350px] border rounded-xl px-2 py-4 hover:cursor-pointer hover:bg-gray-100 transform transition duration-200 hover:scale-105">
              <CircleX className="w-10 h-12 ml-4 mr-4 text-primary" />
              <div className="flex-1">
                <p className="text-md font-semibold">
                  Service Deactivation Request
                </p>
                <p className="text-xs text-muted-foreground">
                  Request the cancellation of a subscription, service or other
                  product fulfilled by Gazelle.
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div> */}

      <div className="mt-2 mx-auto mb-4">
        <CancelButton variant="outline">
          <ChevronLeft /> Go Back
        </CancelButton>
      </div>
    </div>
  );
}
